import React from "react";
import FormOne from "../contact/FormOne";

const AboutOne = () => {
  return (
    <section className="section section-padding-equal bg-color-light">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <span className="subtitle">About Us</span>
                <h2 className="title mb--40">AgenSora</h2>

                <p>
                  Where Design Meets Strategy for Social Media Success At
                  AgenSora, we believe social media is more than just a platform
                  – it's a vibrant canvas where brands can connect with their
                  audience on a deeper level. Our team of passionate designers
                  and strategists combines creative flair with data-driven
                  insights to craft a social media presence that's both visually
                  stunning and strategically sound. We don't just create content
                  – we weave captivating stories that resonate with your
                  audience, fostering brand loyalty and driving growth. Let
                  AgenSora be your design partner, transforming your social
                  media into a powerful tool for success.{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box">
              <h3 className="title">Get a free Keystroke quote now</h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
            alt="line"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="line"
          />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
