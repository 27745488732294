import React, { useEffect, useState } from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ServiceProp from "../component/service/ServiceProp";
import ServiceData from "../data/service/ServiceMain.json";
import { slugify } from "../utils";
import { Link, useParams } from "react-router-dom";

const allData = ServiceData;

const ServiceOne = () => {
  const { id } = useParams();
  const [data, setData] = useState({});

  



 useEffect(() => {
allData.map((data) =>  data.title === id ? setData(data):"");
 
   
 },[id])
 console.log("Alldata>>>>>>>", data);
 
  const designData = allData.filter(
    (data) =>
      slugify(data.cate ? data.cate : "") === "Social Media Content Creation"
  );
  const developmentData = allData.filter(
    (data) =>
      slugify(data.cate ? data.cate : "") === "Brand Strategy and Development"
  );
  const marketingData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "online-marketing"
  );
  const businessData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "Social Media Management"
  );
  const technologyData = allData.filter(
    (data) =>
      slugify(data.cate ? data.cate : "") === "Social Media Advertising"
  );
 

  return (
    <>
      <SEO title="Service One" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title={data.title}
          paragraph={data.description}
          styleClass=""
          mainThumb="/images/banner/banner-thumb-4.png"
        />
        <div className="service-scroll-navigation-area">
          {/* Service Nav */}

          <nav
            id="onepagenav"
            className="service-scroll-nav navbar onepagefixed"
          >
            <div className="container">
              <ul className="nav nav-pills">
                <li className="nav-item">
                <Link to={process.env.PUBLIC_URL + "/Social Media Content Creation"}>Social Media Content Creation</Link>
                </li>
                <li className="nav-item">
                <Link to={process.env.PUBLIC_URL + "/Brand Strategy and Development"}>Brand Strategy and Development</Link>
                </li>
                <li className="nav-item">
                <Link to={process.env.PUBLIC_URL + "/Social Media Management"}>Social Media Management</Link>
                </li>
                <li className="nav-item">
                <Link to={process.env.PUBLIC_URL + "/Social Media Advertising"}>Social Media Advertising</Link>
                </li>
                <li className="nav-item">
                <Link to={process.env.PUBLIC_URL + "/Brand Strategy and Development"}>Social Media Analytics and Reporting</Link>
                </li>
              </ul>
            </div>
          </nav>

        

        

          {/* <div className="section section-padding" id="section5">
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Social Media Analytics and Reporting:"
                description="Tracking key metrics (engagement, reach, website traffic)
                Reporting on campaign performance and ROI
                Insights and recommendations for future strategy"
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-4 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={technologyData}
                />
              </div>
            </div>
          </div> */}
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ServiceOne;
